import React from "react"
import "./faq.css"
import tenantConfig from "../../tenantConfig.json"

function TermsAndConditions({ headerSpace }) {
  return (
    <div className="faq-outer-view">
      <div className="faq-inner-view">
        <div className="faq-main-view">
          <div className={`toc-title-style ${headerSpace ? "my-5" : "mb-5"}`}>
            Terms &amp; Conditions
          </div>
          <ul className="space-y-4">
            <li>
              <h2 className="toc-inner-title">Cancellation Policy</h2>
              <p className="text-color-dark">
                The following represents an agreement between{" "}
                {tenantConfig?.name} (the &ldquo;Restaurant&rdquo;) and you and
                outlines specific conditions relating to your booking (the
                &ldquo;Agreement&rdquo;).
                <br />
                <br />
                In consideration for the restaurant making a reservation on your
                behalf, you are required to provide us with credit or debit card
                details to secure all parties. No money will be charged onto
                your card other than in accordance with the cancellation policy
                below.
                <br />
                <br />
                If the restaurant is unable to perform its obligations under
                this agreement for any reason beyond the restaurant&rsquo;s
                reasonable control, including but not limited to: fire; flood or
                other emergency conditions; or the carrying out of necessary and
                essential repairs to the restaurant (or any delay to the
                completion of such repairs), the restaurant shall have no
                liability to you of any kind in respect of such non-performance.
                <br />
                <br />
                Cancellations (including partial cancellations) must be actioned
                using the link in your confirmation email, 24 hours&rsquo; prior
                to your reservation date.
                <br />
                <br />
                In the event that the reservation is not honoured in whole by
                you or is cancelled with less than the required notice period,
                the restaurant may, at its discretion, charge &pound;X per
                person to the credit or debit card that you have provided to
                secure your reservation.
                <br />
                <br />
                Please note that the above will vary for special events and
                private dining. Please speak to a member of our team for full
                details.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default TermsAndConditions
