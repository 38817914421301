import React, { useEffect } from "react"
import { Outlet, useLocation, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"

import GoBack from "../../components/custom/Goback"
import AvailabilityInfo from "./AvailabilityInfo"
import InformationInfo from "./InformationInfo"
import ConfirmInfo from "./ConfirmInfo"
import CalenderInfo from "./CalenderInfo"
import { fetchRestaurantInfo } from "../../api/v2/reservations"
import { fetchBrandDetails } from "../../api/v2/brand"
import "./bookingStyle.css"
import SkeletonView from "../../components/custom/SkeletonView"
import CustomFontComponent from "../../components/custom/CustomFontComponent"
import tenantConfig from "../../tenantConfig.json"

const BookingInfo = () => {
  const { tenantId, subtenantId } = useParams()
  const location = useLocation()

  const backButtonPaths = [
    `/booking-info/${tenantId}/availability`,
    `/booking-info/${tenantId}/information`,
    `/booking-info/${tenantId}/${subtenantId}/availability`,
    `/booking-info/${tenantId}/${subtenantId}/information`,
  ]
  const showBackButton = backButtonPaths.includes(location.pathname)

  const { data, isLoading, isError } = useQuery({
    queryKey: ["restaurant-info"],
    queryFn: () => fetchRestaurantInfo(tenantId, subtenantId),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!tenantId,
  })

  const { data: brandData } = useQuery({
    queryKey: ["brand-info"],
    queryFn: () => fetchBrandDetails(tenantId, subtenantId),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!tenantId,
  })

  useEffect(() => {
    const handleBeforeUnload = () => {
      window.history.replaceState({}, document.title)
    }
    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])
  if (isError || !tenantId) {
    return (
      <div className="no-resturant-outer-view">
        <h1 className="no-resturant-view">Resturant Not Found</h1>
      </div>
    )
  }

  return (
    <CustomFontComponent fontFamily={tenantConfig?.font ?? ""}>
      <div className="booking-main-div">
        <div className="booking-header-section">
          <div className="booking-header-inner">
            {showBackButton && (
              <div className="back-icon-style">
                <GoBack />
              </div>
            )}
            <img src={tenantConfig?.logo} alt="Zumi" className="logo-width" />
          </div>
          {isLoading ? (
            <SkeletonView className="skeleton-main" />
          ) : (
            data && (
              <Outlet
                context={{
                  restaurantData: data,
                  guestsNumber: data?.max_splits,
                  brandData,
                  localJsonData: tenantConfig,
                }}
              />
            )
          )}
        </div>
      </div>
    </CustomFontComponent>
  )
}

export default BookingInfo

export { CalenderInfo, AvailabilityInfo, InformationInfo, ConfirmInfo }
