function DetermineHostname() {
  let hostname
  let hostV2

  switch (window.location.hostname) {
    case "zumipay-blue.web.app":
      hostname = "https://us-central1-zumipay-blue.cloudfunctions.net/"
      hostV2 = "https://europe-west2-zumipay-blue.cloudfunctions.net/"
      break
    case "zumipay-prod.web.app":
    case "client.zumi.app":
    case "leven-hotel.web.app":
    case "leven-hotel.firebaseapp.com":
    case "michael-nadra.web.app":
    case "michael-nadra.firebaseapp.com":
      hostname = "https://us-central1-zumipay-prod.cloudfunctions.net/"
      hostV2 = "https://europe-west2-zumipay-prod.cloudfunctions.net/"
      break
    case "client-staging.zumi.app":
    case "zumipay-staging.firebaseapp.com":
    case "zumipay-staging.web.app":
    case "leven-hotel-staging.web.app":
    case "leven-hotel-staging.firebaseapp.com":
    case "michael-nadra-staging.web.app":
    case "michael-nadra-staging.firebaseapp.com":
      hostname = "https://us-central1-zumipay-staging.cloudfunctions.net/"
      hostV2 = "https://europe-west2-zumipay-staging.cloudfunctions.net/"
      break
    case "localhost":
      hostname = "https://us-central1-zumipay-staging.cloudfunctions.net/"
      hostV2 = "https://europe-west2-zumipay-staging.cloudfunctions.net/"
      // hostname = "http://localhost:5001/zumipay-staging/us-central1/"
      // hostV2 = "http://localhost:5001/zumipay-staging/europe-west2/"
      break
    default:
      hostname = "https://server.zumi.ngrok.io/zumipay-staging/us-central1/" // Only use this locally never in staging or prod envs
      hostV2 = "https://server.zumi.ngrok.io/zumipay-staging/europe-west2/"
      break
  }
  return { hostname, hostV2 }
}

export default DetermineHostname
