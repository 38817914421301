import React, { useState } from "react"
import "./SwipeButtonDark.css" // Import your CSS file

function Slider({ onSwipe }) {
  const [initialMouse, setInitialMouse] = useState(0)
  const [slideMovementTotal, setSlideMovementTotal] = useState(0)
  const [mouseIsDown, setMouseIsDown] = useState(false)
  const sliderRef = React.useRef(null)

  React.useEffect(() => {
    const slider = sliderRef.current

    function handleStart(event) {
      setMouseIsDown(true)
      const buttonBackgroundWidth =
        document.getElementById("button-background").offsetWidth
      const sliderWidth = slider.offsetWidth
      setSlideMovementTotal(buttonBackgroundWidth - sliderWidth + 10)
      setInitialMouse(event.clientX || event.touches[0].pageX)
    }

    function handleEnd(event) {
      if (!mouseIsDown) return
      setMouseIsDown(false)
      const currentMouse = event.clientX || event.changedTouches[0].pageX
      const relativeMouse = currentMouse - initialMouse

      if (relativeMouse < slideMovementTotal) {
        fadeTextAndAnimate(".slide-text", 1, 200)
        animateSlider(slider, 5, 200)
        return
      }
      slider.classList.add("unlocked")
      document.getElementById("locker").textContent = "Done"
      onSwipe()
      setTimeout(() => {
        slider.addEventListener("click", handleClick)
      }, 0)
    }

    function handleClick() {
      const slider = sliderRef.current
      if (!slider.classList.contains("unlocked")) return
      slider.classList.remove("unlocked")
      document.getElementById("locker").textContent = ""
      slider.removeEventListener("click", handleClick)
    }

    function handleMove(event) {
      if (!mouseIsDown) return
      const currentMouse = event.clientX || event.touches[0].pageX
      const relativeMouse = currentMouse - initialMouse
      const slidePercent = 1 - relativeMouse / slideMovementTotal

      fadeText(".slide-text", slidePercent)

      if (relativeMouse <= 0) {
        slider.style.left = "10px"
        return
      }
      if (relativeMouse >= slideMovementTotal - 10) {
        slider.style.left = slideMovementTotal + "px"
        return
      }
      slider.style.left = relativeMouse - 10 + "px"
    }

    document.body.addEventListener("mouseup", handleEnd)
    document.body.addEventListener("touchend", handleEnd)
    document.body.addEventListener("mousemove", handleMove)
    document.body.addEventListener("touchmove", handleMove)

    slider.addEventListener("mousedown", handleStart)
    slider.addEventListener("touchstart", handleStart)

    return () => {
      document.body.removeEventListener("mouseup", handleEnd)
      document.body.removeEventListener("touchend", handleEnd)
      document.body.removeEventListener("mousemove", handleMove)
      document.body.removeEventListener("touchmove", handleMove)
      slider.removeEventListener("mousedown", handleStart)
      slider.removeEventListener("touchstart", handleStart)
    }
  }, [initialMouse, mouseIsDown, slideMovementTotal])

  function fadeText(selector, opacity) {
    document.querySelectorAll(selector).forEach(function (element) {
      element.style.opacity = opacity
    })
  }

  function fadeTextAndAnimate(textSelector, opacity, duration) {
    fadeText(textSelector, opacity)
    animateSlider(sliderRef.current, 10, duration)
  }

  function animateSlider(element, position, duration) {
    let start = null
    const startPosition = parseFloat(element.style.left)
    const distance = position - startPosition

    function step(timestamp) {
      if (!start) start = timestamp
      const progress = timestamp - start
      element.style.left =
        startPosition + distance * Math.min(progress / duration, 1) + "px"
      if (progress < duration) {
        window.requestAnimationFrame(step)
      }
    }
    window.requestAnimationFrame(step)
  }

  return (
    <>
      <div id="button-background">
        <span className="slide-text">Swipe to pay</span>
        <div id="slider" ref={sliderRef}>
          <i id="locker" className="material-icons"></i>
        </div>
      </div>
    </>
  )
}

export default Slider
