import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import {
  useParams,
  useNavigate,
  useLocation,
  Link,
  useOutletContext,
} from "react-router-dom"
import { loadStripe } from "@stripe/stripe-js/pure"
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js"
import moment from "moment"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
import Select from "react-select"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"

import CheckBox from "../../components/custom/CheckBox"
import { CreditCardIcon, SpinnerIconWhite } from "../../components/custom/Icons"
import countryCodes from "../../utils/country_codes.json"
import {
  confirmReservationV2,
  getClientSecret,
  phoneLookUp,
} from "../../api/v2/reservations"
import logo from "../../assets/zumi_logo_2.svg"
import "./bookingStyle.css"

const InformationForm = () => {
  const stripe = useStripe()
  const elements = useElements()
  const { tenantId, subtenantId } = useParams()
  const nav = useNavigate()
  const { state } = useLocation()
  const { selectedDateTime, guestCount } = state ?? {
    guestCount: null,
    selectedDateTime: null,
  }
  const { localJsonData } = useOutletContext()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [guestPhone, setguestPhone] = useState()
  const [countryCode, setcountryCode] = useState({
    value: "+44",
    label: "United Kingdom +44",
  })
  const [guestEmail, setguestEmail] = useState()
  const [acceptPolicy, setacceptPolicy] = useState(true)
  const [acceptRMails, setacceptRMails] = useState(true)
  const [acceptZumiMails, setacceptZumiMails] = useState(true)
  const [isDisabled, setIsDisabled] = useState(false)
  const [expanded, setExpanded] = useState(["payment-accordion"])
  const handleAccordionsetFAQ = (expandedItems) => {
    setExpanded(expandedItems)
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const confirmReservationMutationV2 = useMutation({
    mutationFn: confirmReservationV2,
    onError: (error) => {
      toast.error(error.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
      setIsDisabled(false)
    },
  })

  const clientSecretMutation = useMutation({
    mutationFn: getClientSecret,
  })

  const submit = async () => {
    setIsDisabled(true)
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setIsDisabled(false)
      return null
    }
    const { error: submitError } = await elements.submit()
    if (submitError) {
      toast.error(submitError.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
      setIsDisabled(false)
      return
    }

    const { client_secret, customerID } =
      await clientSecretMutation.mutateAsync({
        guestName: `${firstName} ${lastName}`,
        guestEmail,
      })

    const { error, setupIntent } = await stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret: client_secret,
      confirmParams: {
        return_url: subtenantId
          ? `${window.location.origin}/booking-info/${tenantId}/${subtenantId}/confirm`
          : `${window.location.origin}/booking-info/${tenantId}/confirm`,
      },
      redirect: "if_required",
    })
    if (error) {
      toast.error(error.message, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      })
      setIsDisabled(false)
      return
    }

    confirmReservationMutationV2.mutate(
      {
        // restaurantID: restaurant,
        dateTime: moment(selectedDateTime),
        guestsCount: guestCount,
        firstName,
        lastName,
        guestPhone: `${countryCode?.value}${guestPhone}`,
        guestEmail,
        stripePaymentID: setupIntent.payment_method,
        customerID: customerID,
        acceptPolicy,
        acceptRestaurantMails: acceptRMails,
        acceptZumiMails,
        tenantId,
        subtenantId,
      },
      {
        onSuccess: (data) => {
          goToNext(data.NewBookingID, data.reservationCode, data.last4)
        },
      },
    )
  }

  const goToNext = (newBookingID, reservationCode, last4) => {
    if (subtenantId) {
      nav(`/booking-info/${tenantId}/${subtenantId}/confirm`, {
        state: {
          selectedDateTime,
          guestCount,
          last4,
          newBookingID,
          reservationCode,
        },
        replace: true,
      })
    } else {
      nav(`/booking-info/${tenantId}/confirm`, {
        state: {
          selectedDateTime,
          guestCount,
          last4,
          newBookingID,
          reservationCode,
        },
        replace: true,
      })
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (!state) {
      subtenantId
        ? nav(`/booking-info/${tenantId}/${subtenantId}`)
        : nav(`/booking-info/${tenantId}`)
    }
  }, [state, nav, tenantId, subtenantId])

  const options = countryCodes.map((i) => ({
    value: i.dial_code,
    label: `${i.name} ${i.dial_code}`,
  }))
  const handleChange = (selectedOption) => {
    setcountryCode(selectedOption)
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "9rem",
      minHeight: "3rem",
      height: state.isFocused ? "auto" : "3rem",
      transition: "height 0.3s ease",
    }),
    option: (provided) => ({
      ...provided,
      padding: "0.5rem",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      width: "20rem",
    }),
    placeholder: (provided) => ({
      ...provided,
      display: "none",
    }),
  }

  if (!state) return null

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <form
        onSubmit={handleSubmit(() => {
          submit()
        })}
      >
        <div className="info-main-div">
          <div className="info-top-sec">
            <p className="info-guest-text">{guestCount} guests</p>
            <p className="info-guest-text">
              {moment(selectedDateTime).format("ddd, MMM D")}
            </p>
            <p className="info-guest-text">
              {moment(selectedDateTime).format("HH:mm")}
            </p>
          </div>
          <div className="info-middle-sec">
            <h1 className="info-middle-text">Guest information</h1>
            <div className="info-inner-div">
              <div>
                <input
                  type="text"
                  placeholder="First name"
                  className="info-input-style"
                  {...register("firstName", {
                    required: {
                      value: true,
                      message: "This Field is required",
                    },
                    minLength: {
                      value: 2,
                      message: "At least 2 letters required",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message:
                        "First name cannot include any special characters or numbers",
                    },
                  })}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                {errors.firstName && (
                  <span className="error-text">{errors.firstName.message}</span>
                )}
              </div>
              <div>
                <input
                  type="text"
                  placeholder="Last name"
                  className="info-input-style"
                  {...register("lastName", {
                    required: {
                      value: true,
                      message: "This Field is required",
                    },
                    minLength: {
                      value: 2,
                      message: "At least 2 letters required",
                    },
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message:
                        "Last name cannot include any special characters or numbers",
                    },
                  })}
                  onChange={(e) => setLastName(e.target.value)}
                />
                {errors.lastName && (
                  <span className="error-text">{errors.lastName.message}</span>
                )}
              </div>
              <div className="w-full">
                <div className="country-view">
                  <Select
                    id="country-select"
                    value={countryCode}
                    onChange={handleChange}
                    options={options}
                    placeholder="Country"
                    styles={customStyles}
                  />
                  <input
                    type="text"
                    placeholder="Phone"
                    className="info-input-style flex-1"
                    {...register("guestPhone", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },
                      pattern: {
                        value: /^(\+?\d{1,3}[- ]?)?\d{10}$/,
                        message: "Please Enter a valid phone number",
                      },
                      validate: async () => {
                        setIsDisabled(true)
                        const phoneValidation = await phoneLookUp({
                          guestPhone: `${countryCode?.value}${guestPhone}`,
                        })
                        setIsDisabled(false)
                        return (
                          phoneValidation.valid ||
                          "Please Enter a valid phone number"
                        )
                      },
                    })}
                    onChange={(e) => setguestPhone(e.target.value)}
                  />
                </div>
                {errors.guestPhone && (
                  <span className="error-text">
                    {errors.guestPhone.message}
                  </span>
                )}
              </div>
              <div>
                <input
                  type="email"
                  placeholder="Email"
                  className="info-input-style"
                  required
                  {...register("guestEmail", {
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Please Enter a valid email",
                    },
                  })}
                  onChange={(e) => setguestEmail(e.target.value)}
                />
                {errors.guestEmail && (
                  <span className="error-text">
                    {errors.guestEmail.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          {/* credit card form */}
          <div className="w-full">
            <h1 className="info-middle-text">Credit card required</h1>
            <div className="mb-4">
              <span className="credit-card-desc">
                {localJsonData?.name} requires a credit card to secure this
                booking. All credit card information will be processed securely.
                No-shows or cancellations less than 1 day in advance will be
                subject to a charge of £10 per person.
              </span>
              <br />
              <Link
                to={"/toc"}
                target="_blank"
                className="terms-condition-text"
              >
                View terms and conditions.
              </Link>
            </div>
            <div className="info-bottom-view">
              <section className="bottom-first-sec">
                <img src={logo} alt="Company Logo" className="mx-auto" />
                <p className="info-intro-text">
                  Introducing an elegant new
                  <br /> checkout experience
                </p>
              </section>
              <section className="bottom-first-sec mt-8">
                <p>
                  {localJsonData.name} is partnering with Zumi to offer an
                  incredible new end of night checkout experience. At the end of
                  your meal, simply walk out and have your card charged
                  automatically 1 hour later. You can also check your bill, pay
                  in full and split the bill with others conveniently on your
                  mobile phone.
                </p>
                <button
                  form={null}
                  onClick={(e) => {
                    e.preventDefault()
                    window.open("https://client.zumi.app/faq-support", "_blank")
                  }}
                  className="btn-white-default learn-more-text"
                >
                  Learn More
                </button>
              </section>
            </div>
            <div className="payment-div">
              <Accordion
                preExpanded={expanded}
                onChange={handleAccordionsetFAQ}
                allowZeroExpanded={true}
                className="custom-accordion"
              >
                <AccordionItem uuid="payment-accordion">
                  <AccordionItemButton>
                    <div className="payment-inner-div">
                      <span className="inline-block">
                        <CreditCardIcon />
                      </span>
                      <p className="info-middle-text">Payment</p>
                    </div>
                  </AccordionItemButton>
                  <AccordionItemPanel>
                    <div className="payment-element-div">
                      <PaymentElement />
                    </div>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
            <div className="policyChecksContainer">
              <div className="check-box-style">
                <CheckBox
                  {...register("acceptPolicy", {
                    validate: () => {
                      return acceptPolicy === true
                    },
                  })}
                  checked={acceptPolicy}
                  onClick={() => {
                    setacceptPolicy(!acceptPolicy)
                  }}
                />
                <span
                  onClick={() => {
                    setacceptPolicy(!acceptPolicy)
                  }}
                  className="policy-text"
                >
                  I accept the cancellation policy*
                </span>
              </div>
              {errors.acceptPolicy && (
                <span className="agree-error-style">
                  Checking this field is required
                </span>
              )}
              <div className="check-box-style">
                <CheckBox
                  {...register("acceptRMails", {
                    validate: () => {
                      return acceptRMails === true
                    },
                  })}
                  checked={acceptRMails}
                  onClick={() => {
                    setacceptRMails(!acceptRMails)
                  }}
                />
                <span
                  onClick={() => {
                    setacceptRMails(!acceptRMails)
                  }}
                  className="policy-text"
                >
                  Receive emails and texts from {localJsonData.name} about your
                  booking*
                </span>
              </div>
              {errors.acceptRMails && (
                <span className="agree-error-style">
                  Checking this field is required
                </span>
              )}
              <div className="check-box-style">
                <CheckBox
                  {...register("acceptZumiMails", {
                    validate: () => {
                      return acceptZumiMails === true
                    },
                  })}
                  checked={acceptZumiMails}
                  onClick={() => setacceptZumiMails(!acceptZumiMails)}
                />
                <span
                  onClick={() => setacceptZumiMails(!acceptZumiMails)}
                  className="policy-text"
                >
                  Receive emails and texts from {localJsonData.name} about your
                  bill and payment*
                </span>
              </div>
              {errors.acceptZumiMails && (
                <span className="agree-error-style">
                  Checking this field is required
                </span>
              )}
              <div>
                <span className="by-clicking-style">
                  By clicking <strong>&quot;complete reservation&quot;</strong>{" "}
                  you agree to &nbsp;
                </span>
                <Link
                  to={"/toc"}
                  target="_blank"
                  className="terms-condition-text"
                >
                  Zumi Pay Terms of Service and Policy
                </Link>
              </div>
            </div>
            <div className="info-bottom-btn">
              <button
                type="submit"
                onClick={handleSubmit(() => {
                  submit()
                })}
                disabled={isDisabled}
                className="btn-dark-default"
              >
                {isDisabled && <SpinnerIconWhite />} Complete reservation
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

loadStripe.setLoadParameters({ advancedFraudSignals: false })
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY)

const InformationInfo = () => {
  return (
    <>
      {stripePromise && (
        <Elements
          stripe={stripePromise}
          options={{
            mode: "setup",
            currency: "gbp",
          }}
        >
          <InformationForm />
        </Elements>
      )}
    </>
  )
}

export default InformationInfo
