import React, { useEffect } from "react"
import moment from "moment-timezone"
import Hotjar from "@hotjar/browser"
import { ToastContainer } from "react-toastify"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

import Home from "./screens/Home"
import TestTag from "./components/TestTag"
import Reservations, {
  GuestConfirm,
  GuestInformation,
} from "./screens/reservations"
import PublicRoute from "./components/router/PublicRoute"
import ScrollToTop from "./components/custom/ScrollToTop"
import BillsInfo from "./screens/payingBillInfo"
import PayingBillInfo from "./screens/payingBillInfo/PayingBillInfo"
import SplitBillInfo from "./screens/payingBillInfo/SplitBillInfo"
import PaymentFormInfo from "./screens/payingBillInfo/PaymentFormInfo"
import SucceededPaymentInfo from "./screens/payingBillInfo/SucceededPaymentInfo"
import FAQSUPPORTS from "./components/TermsAndConditions/FAQS"
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions"
import BookingInfo, {
  AvailabilityInfo,
  CalenderInfo,
  ConfirmInfo,
  InformationInfo,
} from "./screens/bookingInfo"
import ModifyReservation from "./screens/modifyReservation"
import ModifyAvailability from "./screens/modifyReservation/ModifyAvailability"
import ModifyCalender from "./screens/modifyReservation/ModifyCalender"
import ModifyInformation from "./screens/modifyReservation/ModifyInformation"
import ModifyCancelled from "./screens/modifyReservation/ModifyCancelledPage"
import tenantConfig from "./tenantConfig.json"

const queryClient = new QueryClient()
moment.tz.setDefault("Europe/London")

const HOTJAR_ENABLED = process.env.REACT_APP_HOTJAR_ENABLED

if (HOTJAR_ENABLED) {
  const HOTJAR_SITE_ID = process.env.REACT_APP_HOTJAR_SITE_ID
  const HOTJAR_VERSION = process.env.REACT_APP_HOTJAR_VERSION
  Hotjar.init(HOTJAR_SITE_ID, HOTJAR_VERSION)
}

function App() {
  const ENV = process.env.REACT_APP_ENVIRONMENT
  useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]')
    if (metaThemeColor) {
      if (ENV === "production") {
        metaThemeColor.setAttribute("content", "#18243a")
      } else {
        metaThemeColor.setAttribute("content", "#EE4B2B")
      }
    }
  }, [])

  return (
    <Router>
      <div className="text-white min-h-screen bg-color-customblue app">
        <ScrollToTop />
        <TestTag />
        <QueryClientProvider client={queryClient}>
          <Routes>
            {/* home and 404 page route */}
            <Route path="/" element={<PublicRoute />}>
              <Route index element={<Home />} />
              <Route path="*" element={<Home />} />
            </Route>
            {/* booking-info tenantId */}
            <Route path="/booking-info/:tenantId" element={<BookingInfo />}>
              <Route index element={<CalenderInfo />} />
              <Route
                path="/booking-info/:tenantId/availability"
                element={<AvailabilityInfo />}
              />
              <Route
                path="/booking-info/:tenantId/information"
                element={<InformationInfo />}
              />
              <Route
                path="/booking-info/:tenantId/confirm"
                element={<ConfirmInfo />}
              />
            </Route>
            {/* booking-info tenantId & subtenantId */}
            <Route
              path="/booking-info/:tenantId/:subtenantId"
              element={<BookingInfo />}
            >
              <Route index element={<CalenderInfo />} />
              <Route
                path="/booking-info/:tenantId/:subtenantId/availability"
                element={<AvailabilityInfo />}
              />
              <Route
                path="/booking-info/:tenantId/:subtenantId/information"
                element={<InformationInfo />}
              />
              <Route
                path="/booking-info/:tenantId/:subtenantId/confirm"
                element={<ConfirmInfo />}
              />
            </Route>
            {/* reservations tenantId */}
            <Route
              path="/reservations/:tenantId/:bookingID"
              element={<Reservations />}
            >
              <Route index element={<GuestInformation />} />
              <Route
                path="/reservations/:tenantId/:bookingID/confirmed"
                element={<GuestConfirm />}
              />
            </Route>
            {/* reservations tenantId & subtenantId */}
            <Route
              path="/reservations/:tenantId/:subtenantId/:bookingID"
              element={<Reservations />}
            >
              <Route index element={<GuestInformation />} />
              <Route
                path="/reservations/:tenantId/:subtenantId/:bookingID/confirmed"
                element={<GuestConfirm />}
              />
            </Route>
            {/* modify-reservation tenantId */}
            <Route
              path="/modify-reservation/:tenantId/:bookingID"
              element={<ModifyReservation />}
            >
              <Route index element={<ModifyInformation />} />
              <Route
                path="/modify-reservation/:tenantId/:bookingID/calender"
                element={<ModifyCalender />}
              />
              <Route
                path="/modify-reservation/:tenantId/:bookingID/availability"
                element={<ModifyAvailability />}
              />
              <Route
                path="/modify-reservation/:tenantId/:bookingID/cancelled"
                element={<ModifyCancelled />}
              />
            </Route>
            {/* modify-reservation tenantId & subtenantId */}
            <Route
              path="/modify-reservation/:tenantId/:subtenantId/:bookingID"
              element={<ModifyReservation />}
            >
              <Route index element={<ModifyInformation />} />
              <Route
                path="/modify-reservation/:tenantId/:subtenantId/:bookingID/calender"
                element={<ModifyCalender />}
              />
              <Route
                path="/modify-reservation/:tenantId/:subtenantId/:bookingID/availability"
                element={<ModifyAvailability />}
              />
              <Route
                path="/modify-reservation/:tenantId/:subtenantId/:bookingID/cancelled"
                element={<ModifyCancelled />}
              />
            </Route>
            {/* bill-info */}
            <Route path="/bill-info/:reservationID" element={<BillsInfo />}>
              <Route index element={<PayingBillInfo />} />
              <Route
                path="/bill-info/:reservationID/split"
                element={<SplitBillInfo />}
              />
              <Route
                path="/bill-info/:reservationID/card"
                element={<PaymentFormInfo />}
              />
              <Route
                path="/bill-info/:reservationID/succeeded"
                element={<SucceededPaymentInfo />}
              />
            </Route>
            {/* faq */}
            <Route path="/faq-supports" element={<FAQSUPPORTS headerSpace />} />
            <Route
              path="/toc"
              element={
                <TermsAndConditions tenantConfig={tenantConfig} headerSpace />
              }
            />
          </Routes>
          <ToastContainer
            position={"bottom-right"}
            autoClose={2000}
            hideProgressBar={false}
            closeOnClick={true}
            draggable={true}
            progress={undefined}
            theme={"dark"}
          />
        </QueryClientProvider>
      </div>
    </Router>
  )
}

export { App }
