import React, { useEffect, useState } from "react"
import {
  useNavigate,
  useParams,
  useLocation,
  useOutletContext,
} from "react-router"
import moment from "moment"
import Calendar from "react-calendar"
import "react-calendar/dist/Calendar.css"
import { addMonths, isBefore, isMonday, isTuesday } from "date-fns"
import CustomFontComponent from "../../components/custom/CustomFontComponent"
import tenantConfig from "../../tenantConfig.json"
import "./modifyReservationStyle.css"

moment.tz.setDefault("Europe/London")

const ModifyCalender = () => {
  const { reservationData } = useOutletContext()
  const [showCalender, setshowCalender] = useState(false)
  const [showClock, setShowClock] = useState(false)
  const [selectedTime, setSelectedTime] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const nav = useNavigate()
  const { tenantId, subtenantId, bookingID } = useParams()
  const { state } = useLocation()

  useEffect(() => {
    if (state) {
      const parsedDateTime = moment(state.dateTime)
      setSelectedDate(parsedDateTime.toDate())
      setSelectedTime(parsedDateTime)
    }
  }, [state])
  const today = new Date()
  const maxDate = addMonths(today, 2)
  const [showTodayText, setShowTodayText] = useState(false)
  const toggleCalenderView = () => setshowCalender(!showCalender)
  const toggleClockView = () => setShowClock(!showClock)
  const onDatePick = (newDate) => {
    const normalizedDate = new Date(
      Date.UTC(newDate.getFullYear(), newDate.getMonth(), newDate.getDate()),
    )
    setSelectedDate(normalizedDate)
    setShowTodayText(normalizedDate?.toDateString() === today?.toDateString())
    setshowCalender(false)
  }
  const isDateDisabled = (date) => {
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)
    date.setHours(0, 0, 0, 0)
    return isBefore(date, currentDate) || isMonday(date) || isTuesday(date)
  }

  const search = () => {
    const selectedDateTime = moment(selectedDate).set({
      hour: selectedTime ? moment(selectedTime, "HH:mm").hour() : 12,
      minute: selectedTime ? moment(selectedTime, "HH:mm").minute() : 30,
    })
    nav(
      `/modify-reservation/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/${bookingID}/availability`,
      {
        state: {
          selectedDateTime: selectedDateTime.format(),
          guestsCount: reservationData?.guestsCount,
        },
      },
    )
  }

  const minTime = "12:00"
  const maxTime = "21:00"

  const generateTimeSlots = (minTime, maxTime, interval) => {
    const slots = []
    let currentTime = moment(minTime, "HH:mm")
    const endTime = moment(maxTime, "HH:mm")
    while (currentTime <= endTime) {
      slots.push(currentTime.format("HH:mm"))
      currentTime.add(interval, "minutes")
    }
    return slots
  }
  const timeSlots =
    minTime && maxTime ? generateTimeSlots(minTime, maxTime, 30) : []

  useEffect(() => {
    if (!state) {
      nav(
        `/modify-reservation/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/${bookingID}`,
      )
    }
  }, [state, nav, tenantId, subtenantId, bookingID])

  if (!state) return null

  return (
    <CustomFontComponent fontFamily={tenantConfig?.font ?? ""}>
      <div className="calender-div">
        <div className="calender-top-section">
          <div className="guest-cale">Guests</div>
          <div className="guest-button-div">
            <p className="w-3">{reservationData.guestsCount}</p>
          </div>
        </div>
        <div className="calender-seperator" />
        <div className="calender-top-section">
          <div className="guest-cale">Date</div>
          <div className="guest-button-div" onClick={toggleCalenderView}>
            {showTodayText
              ? "Today"
              : moment(selectedDate).format("ddd, MMM D")}
          </div>
        </div>
        {showCalender && (
          <Calendar
            onChange={onDatePick}
            value={selectedDate}
            tileDisabled={({ date }) => isDateDisabled(date)}
            maxDate={maxDate}
            minDate={today}
          />
        )}
        <div className="calender-seperator" />
        <div className="calender-top-section">
          <div className="guest-cale">Time</div>
          <div className="guest-button-div" onClick={toggleClockView}>
            {selectedTime ? selectedTime.format("HH:mm") : "All Day"}
          </div>
        </div>
        {showClock && timeSlots.length > 0 && (
          <div className="clock-view">
            {timeSlots.map((time, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectedTime(moment(time, "HH:mm"))
                  setShowClock(false)
                }}
                className={`clock-time ${
                  selectedTime && selectedTime.format("HH:mm") === time
                    ? "bg-color-dark text-white"
                    : "bg-color-body-default"
                }`}
              >
                {time}
              </div>
            ))}
          </div>
        )}
        <div className="search-btn-div">
          <button onClick={search} className="btn-dark-default">
            Search
          </button>
        </div>
      </div>
    </CustomFontComponent>
  )
}

export default ModifyCalender
