import { useParams, useNavigate, useLocation } from "react-router-dom"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import React, { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import moment from "moment"
import { fetchAvailableTimesV2 } from "../../api/v2/reservations"
import SkeletonView from "../../components/custom/SkeletonView"
import "./modifyReservationStyle.css"

const SelectedDateAvailableTimes = ({
  selectedDateTime,
  availabileTimes,
  next,
  slotDateTime,
}) => {
  return (
    <div className="available-main-div">
      <div className="available-container">
        {moment(slotDateTime).format("ddd, MMM D")}
        {availabileTimes.length > 0 ? (
          <div className="available-time-style">
            {availabileTimes.map((t, index) => {
              const isSelectedTime = moment(selectedDateTime).isSame(moment(t))
              return (
                <div
                  onClick={() => next(t)}
                  key={index}
                  className={`available-time-view ${isSelectedTime ? "bg-color-dark text-white" : "bg-color-body-default text-color-dark"} `}
                >
                  {moment(t).format("HH:mm")}
                </div>
              )
            })}
          </div>
        ) : (
          <>
            <p className="no-time-view">No Reservations available for today</p>
          </>
        )}
      </div>
    </div>
  )
}

const ModifyAvailability = () => {
  const nav = useNavigate()
  const { state } = useLocation()
  const { tenantId, subtenantId, bookingID } = useParams()
  const { selectedDateTime, guestsCount } = state ?? {
    selectedDateTime: moment(),
  }
  const firstDateTime = moment(selectedDateTime).format()
  const secondDateTime = moment(selectedDateTime).add(1, "days").format()
  const thirdDateTime = moment(selectedDateTime).add(2, "days").format()

  const firstDateQuery = useQuery({
    queryKey: ["availabileTimes", firstDateTime, guestsCount],
    queryFn: () =>
      fetchAvailableTimesV2(firstDateTime, guestsCount, tenantId, subtenantId),
    enabled: !!guestsCount,
  })

  const secondDateQuery = useQuery({
    queryKey: ["availabileTimes", secondDateTime, guestsCount],
    queryFn: () =>
      fetchAvailableTimesV2(secondDateTime, guestsCount, tenantId, subtenantId),
    enabled: !!firstDateQuery?.data,
  })

  const thirdDateQuery = useQuery({
    queryKey: ["availabileTimes", thirdDateTime, guestsCount],
    queryFn: () =>
      fetchAvailableTimesV2(thirdDateTime, guestsCount, tenantId, subtenantId),
    enabled: !!secondDateQuery?.data,
  })

  const goToInformation = (time) => {
    nav(
      `/modify-reservation/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/${bookingID}`,
      {
        state: {
          selectedDateTime: moment(time).format(),
        },
      },
    )
  }

  useEffect(() => {
    if (!state) {
      subtenantId
        ? nav(`/booking-info/${tenantId}/${subtenantId}`)
        : nav(`/booking-info/${tenantId}`)
    }
  }, [state, nav, tenantId, subtenantId])

  if (!state) return null

  return (
    <div className="middle-container">
      {firstDateQuery?.isLoading && (
        <div className="middle-skeleton-view">
          <SkeletonView className="skeleton-gray" />
        </div>
      )}
      {firstDateQuery?.data && (
        <SelectedDateAvailableTimes
          selectedDateTime={selectedDateTime}
          slotDateTime={firstDateTime}
          availabileTimes={firstDateQuery.data.available_times}
          next={goToInformation}
        />
      )}
      <div className="bottom-section-view">
        <Accordion allowZeroExpanded={true} className="custom-accordion">
          <AccordionItem>
            <AccordionItemButton>
              <p className="bottom-text-style">View additional dates</p>
            </AccordionItemButton>
            <AccordionItemPanel>
              {secondDateQuery?.data && (
                <SelectedDateAvailableTimes
                  selectedDateTime={selectedDateTime}
                  slotDateTime={secondDateTime}
                  availabileTimes={secondDateQuery?.data?.available_times}
                  next={goToInformation}
                />
              )}
              {secondDateQuery?.isLoading && (
                <div className="middle-skeleton-view">
                  <SkeletonView className="skeleton-gray" />
                </div>
              )}
              {thirdDateQuery?.data && (
                <SelectedDateAvailableTimes
                  selectedDateTime={selectedDateTime}
                  slotDateTime={thirdDateTime}
                  availabileTimes={thirdDateQuery?.data?.available_times}
                  next={goToInformation}
                />
              )}
              {thirdDateQuery?.isLoading && (
                <div className="middle-skeleton-view">
                  <SkeletonView className="skeleton-gray" />
                </div>
              )}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  )
}

export default ModifyAvailability
