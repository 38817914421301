import React, {
  useParams,
  useNavigate,
  useLocation,
  Link,
} from "react-router-dom"
import { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import moment from "moment"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"

import { fetchAvailableTimesV2 } from "../../api/v2/reservations"
import SkeletonView from "../../components/custom/SkeletonView"
import "./bookingStyle.css"

const SelectedDateAvailableTimes = ({
  selectedDateTime,
  availabileTimes,
  waitingList,
  next,
  slotDateTime,
}) => {
  return (
    <div className="available-main-div">
      <div className="available-container">
        {moment(slotDateTime).format("ddd, MMM D")}
        {availabileTimes.length > 0 ? (
          <div className="available-time-style">
            {availabileTimes.map((t, index) => {
              const isSelectedTime = moment(selectedDateTime).isSame(moment(t))
              return (
                <div
                  onClick={() => next(t)}
                  key={index}
                  className={`available-time-view ${isSelectedTime ? "bg-color-dark text-white" : "bg-color-body-default text-color-dark"} `}
                >
                  {moment(t).format("HH:mm")}
                </div>
              )
            })}
          </div>
        ) : (
          <>
            <p className="no-time-view">No Reservations available for today</p>
            {waitingList && waitingList.length > 0 && (
              <Link to={waitingList} className="waiting-list-style">
                Join our waiting list
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  )
}

const AvailabilityInfo = () => {
  const { tenantId, subtenantId } = useParams()
  const nav = useNavigate()
  const { state } = useLocation()
  useEffect(() => {
    if (!state) {
      subtenantId
        ? nav(`/booking-info/${tenantId}/${subtenantId}`)
        : nav(`/booking-info/${tenantId}`)
    }
  }, [state, nav, tenantId, subtenantId])

  const { selectedDateTime, guestCount } = state ?? {
    guestCount: null,
    selectedDateTime: moment(),
  }

  const firstDateTime = moment(selectedDateTime).format()
  const secondDateTime = moment(selectedDateTime).add(1, "days").format()
  const thirdDateTime = moment(selectedDateTime).add(2, "days").format()

  const firstDateQuery = useQuery({
    queryKey: ["availabileTimes", firstDateTime, guestCount],
    queryFn: () =>
      fetchAvailableTimesV2(firstDateTime, guestCount, tenantId, subtenantId),
    enabled: !!guestCount,
  })

  const secondDateQuery = useQuery({
    queryKey: ["availabileTimes", secondDateTime, guestCount],
    queryFn: () =>
      fetchAvailableTimesV2(secondDateTime, guestCount, tenantId, subtenantId),
    enabled: !!firstDateQuery?.data,
  })

  const thirdDateQuery = useQuery({
    queryKey: ["availabileTimes", thirdDateTime, guestCount],
    queryFn: () =>
      fetchAvailableTimesV2(thirdDateTime, guestCount, tenantId, subtenantId),
    enabled: !!secondDateQuery?.data,
  })

  const goToInformation = (time) => {
    if (subtenantId) {
      nav(`/booking-info/${tenantId}/${subtenantId}/information`, {
        state: {
          guestCount,
          selectedDateTime: time,
        },
      })
    } else {
      nav(`/booking-info/${tenantId}/information`, {
        state: {
          guestCount,
          selectedDateTime: time,
        },
      })
    }
  }

  if (!state) return null

  return (
    <div className="middle-container">
      {firstDateQuery.isLoading && (
        <div className="middle-skeleton-view">
          <SkeletonView className="skeleton-gray" />
        </div>
      )}
      {firstDateQuery.data && (
        <SelectedDateAvailableTimes
          selectedDateTime={selectedDateTime}
          slotDateTime={firstDateTime}
          availabileTimes={firstDateQuery.data.available_times}
          waitingList={firstDateQuery.data.waitingList}
          next={goToInformation}
        />
      )}
      <div className="bottom-section-view">
        <Accordion allowZeroExpanded={true} className="custom-accordion">
          <AccordionItem>
            <AccordionItemButton>
              <p className="bottom-text-style">View additional dates</p>
            </AccordionItemButton>
            <AccordionItemPanel>
              {secondDateQuery.data && (
                <SelectedDateAvailableTimes
                  selectedDateTime={selectedDateTime}
                  slotDateTime={secondDateTime}
                  availabileTimes={secondDateQuery.data.available_times}
                  waitingList={secondDateQuery.data.waitingList}
                  next={goToInformation}
                />
              )}
              {secondDateQuery.isLoading && (
                <div className="middle-skeleton-view">
                  <SkeletonView className="skeleton-gray" />
                </div>
              )}
              {thirdDateQuery.data && (
                <SelectedDateAvailableTimes
                  selectedDateTime={selectedDateTime}
                  slotDateTime={thirdDateTime}
                  availabileTimes={thirdDateQuery.data.available_times}
                  waitingList={thirdDateQuery.data.waitingList}
                  next={goToInformation}
                />
              )}
              {thirdDateQuery.isLoading && (
                <div className="middle-skeleton-view">
                  <SkeletonView className="skeleton-gray" />
                </div>
              )}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  )
}

export default AvailabilityInfo
