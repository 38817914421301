import React from "react"
import { CancelledIcon } from "../../components/custom/Icons"
import { useNavigate, useParams } from "react-router"
import "./modifyReservationStyle.css"

const ModifyCancelled = () => {
  const { tenantId, subtenantId } = useParams()
  const nav = useNavigate()

  const onBookNew = () => {
    nav(`/booking-info/${tenantId}${subtenantId ? `/${subtenantId}` : ""}`)
  }

  return (
    <div className="w-full ">
      <div className="flex flex-col pt-10">
        <div className="fixed w-full h-full flex flex-col items-center">
          <CancelledIcon />
          <div className="w-[212px] text-center text-black text-[22px] font-semibold leading-normal mt-[27px]">
            Your reservation has been cancelled
          </div>
          <div className="w-[280px]  text-center text-black text-base font-medium leading-[17.60px] mt-[30px]">
            Thanks for letting us know! <br />
            We hope to serve you in the future.
          </div>

          <div className="mt-[30px]  h-[34px] px-8 py-3 rounded-3xl border border-black justify-center items-center gap-2 flex">
            <button
              onClick={onBookNew}
              className="text-center text-slate-900 text-base font-medium leading-[17.60px]"
            >
              Book another table?
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModifyCancelled
