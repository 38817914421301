import React from "react"
import { SpinnerIcon } from "../../components/custom/Icons"
import "./modifyReservationStyle.css"

const ConfirmModal = ({
  visible,
  onClick,
  onClose,
  isLoading,
  header,
  discreption,
  confirmText,
}) => {
  if (!visible) return null
  return (
    <div className="bg-[rgba(170,175,193,0.7)] fixed top-0 left-0 z-20 w-full h-full flex justify-center items-center">
      <div className="w-[327px] h-[268px] bg-neutral-50 rounded-[20px] mx-[30px] p-[35px] text-center">
        <div className="w-[253px] h-[25px] text-center text-black text-[22px] font-semibold leading-normal">
          {header}
        </div>
        <div className="w-[274px] h-8 text-center text-black text-base font-medium leading-[17.60px] mt-[10px]">
          {discreption}
        </div>
        <button
          disabled={isLoading}
          onClick={onClick}
          className="w-[266px] h-12 px-8 py-3 bg-slate-900 rounded-3xl justify-center items-center gap-2 inline-flex mt-[15px]"
        >
          <div className="text-center text-amber-50 text-lg font-semibold leading-tight">
            <div className="flex gap-2 items-center">
              {isLoading && <SpinnerIcon />} <span> {confirmText}</span>
            </div>
          </div>
        </button>
        <div className="w-[266px] h-[50px] justify-center items-center inline-flex mt-[12px]">
          <button
            onClick={onClose}
            className="grow shrink basis-0 h-12 px-8 py-3 rounded-3xl border border-slate-900 justify-center items-center gap-2 flex"
          >
            <div className="text-center text-slate-900 text-lg font-semibold leading-tight">
              Nevermind
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ConfirmModal
