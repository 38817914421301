import React, { useState } from "react"
import moment from "moment-timezone"
import { toast } from "react-toastify"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useLocation, useOutletContext, useParams } from "react-router-dom"

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import { SpinnerIcon } from "../../components/custom/Icons"
import { getSubtenantId } from "../../utils/helperFunctions"
import { getOccasions, saveGuestPreferences } from "../../api/v2/reservations"
import "./reservations.css"

moment.tz.setDefault("Europe/London")
const GuestConfirm = () => {
  const params = useParams()
  const tenantId = params?.tenantId
  const subtenantId = getSubtenantId(params?.subtenantId)
  const { restaurantData, reservationData } = useOutletContext()
  const { dateTime, guestsCount, guestEmail, NewBookingID, guestsEmails } =
    reservationData
  const [preferences, setPreferences] = useState({
    occasion: null,
    restrictions: null,
    special: null,
  })
  const { state } = useLocation()
  const saveGuestPreferencesMutation = useMutation({
    mutationFn: saveGuestPreferences,
    onError: (error) => {
      toast.error(error?.message || "Something went wrong!")
    },
    onSuccess: () => {
      toast.success("Preferences saved!")
    },
  })

  const { data: occasions } = useQuery({
    queryKey: ["occasions"],
    queryFn: () => getOccasions(tenantId, subtenantId),
    refetchOnWindowFocus: false,
    enabled: !!tenantId,
  })

  const onEditsSave = async () => {
    saveGuestPreferencesMutation.mutate({
      NewBookingID,
      email: state?.email !== null ? state?.email : guestEmail,
      occasion: preferences.occasion,
      restrictions: preferences.restrictions,
      requests: preferences.special,
    })
  }

  return (
    <div className="guest-confirm-main">
      <div className="border-res-line" />
      <div className="invited-div">RSVP confirmed!</div>
      <div className="border-res-line" />
      <div className="guest-confirm-middle">
        <div className="guest-info-middile-sec">
          <div className="guest-info-middle-text capitalize">
            {restaurantData.name}
          </div>
          <div className="guest-info-middle-text">{guestsCount} guests</div>
          <div className="guest-info-middle-text">
            {moment(dateTime).format("ddd, MMM D, YYYY")}
          </div>
          <div className="guest-info-middle-text">
            {moment(dateTime).format("HH:mm")}
          </div>
        </div>
      </div>
      <div className="border-res-line" />
      <div className="w-full">
        <div className="whos-coming">Who’s coming?</div>
        <div className="guest-list-view">
          <div className="guest-name-view">{guestEmail[0]}</div>
          <div className="guest-name-inner">
            <div className="guest-main-name">{guestEmail.split("@")[0]}</div>
            <div className="guest-organizer">Organizer</div>
          </div>
        </div>
        {guestsEmails?.map((guest, index) => (
          <div key={index} className="guest-list-view">
            <div className="guest-name-view">{guest.email[0]}</div>
            <div className="guest-name-inner">
              <div className="guest-main-name">{guest.email.split("@")[0]}</div>
              <div className="guest-organizer">{guest.status}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="border-res-line" />
      <div className="preference-div">Preferences</div>
      <div className="guest-accordion-div">
        <Accordion allowZeroExpanded className="custom-accordion">
          <AccordionItem>
            <AccordionItemButton>
              <p className="cnf-bottom-title">What’s the occasion?</p>
            </AccordionItemButton>
            <AccordionItemPanel>
              <div className="cnf-bottom-div">
                {occasions?.map((occasion, index) => (
                  <div
                    onClick={() => {
                      if (preferences.occasion === occasion) {
                        setPreferences({ ...preferences, occasion: null })
                      } else setPreferences({ ...preferences, occasion })
                    }}
                    key={index}
                    className={`bottom-option ${preferences.occasion === occasion ? "bg-color-dark text-white" : "bg-color-body-default text-color-dark"}`}
                  >
                    <div className="bottom-option-text">{occasion}</div>
                  </div>
                ))}
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <Accordion allowZeroExpanded className="custom-accordion showBillInfo">
          <AccordionItem>
            <AccordionItemButton>
              <p className="cnf-bottom-title">Any allergies or restrictions?</p>
            </AccordionItemButton>
            <AccordionItemPanel>
              <textarea
                onChange={(e) =>
                  setPreferences({
                    ...preferences,
                    restrictions: e.target.value,
                  })
                }
                className="bottom-textarea"
              />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <Accordion allowZeroExpanded className="custom-accordion showBillInfo">
          <AccordionItem>
            <AccordionItemButton>
              <p className="cnf-bottom-title">Any special requests?</p>
            </AccordionItemButton>
            <AccordionItemPanel>
              <textarea
                onChange={(e) =>
                  setPreferences({
                    ...preferences,
                    special: e.target.value,
                  })
                }
                className="bottom-textarea"
              />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <button
        onClick={onEditsSave}
        disabled={
          !(
            preferences.occasion ||
            preferences.restrictions ||
            preferences.special
          ) || saveGuestPreferencesMutation.isPending
        }
        className="guest-form-button"
      >
        <div className="bottom-btn-inner">
          {saveGuestPreferencesMutation.isPending && <SpinnerIcon />}
          <span>Save</span>
        </div>
      </button>
    </div>
  )
}

export default GuestConfirm
