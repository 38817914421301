import React, { useEffect } from "react"
import { useParams, Outlet, useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import moment from "moment"

import {
  fetchReservationInfo,
  fetchRestaurantInfo,
} from "../../api/v2/reservations"
import GoBack from "../../components/custom/Goback"
import { fetchBrandDetails } from "../../api/v2/brand"
import CustomFontComponent from "../../components/custom/CustomFontComponent"
import SkeletonView from "../../components/custom/SkeletonView"
import tenantConfig from "../../tenantConfig.json"
import "./modifyReservationStyle.css"

const ModifyReservation = () => {
  const nav = useNavigate()
  const { tenantId, subtenantId, bookingID } = useParams()

  const backButtonPaths = [
    `/modify-reservation/${tenantId}/${bookingID}`,
    `/modify-reservation/${tenantId}/${bookingID}/cancelled`,
    `/modify-reservation/${tenantId}/${subtenantId}/${bookingID}`,
    `/modify-reservation/${tenantId}/${subtenantId}/${bookingID}/cancelled`,
  ]
  const showBackButton = backButtonPaths.includes(location.pathname)

  const {
    data: restaurantData,
    isLoading: isLoadingRestaurant,
    isError: isErrorRestaurant,
  } = useQuery({
    queryKey: ["restaurant-info"],
    queryFn: () => fetchRestaurantInfo(tenantId, subtenantId),
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !!tenantId,
  })

  const { data: brandData } = useQuery({
    queryKey: ["brand-info"],
    queryFn: () => fetchBrandDetails(tenantId, subtenantId),
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: !!tenantId,
  })

  const {
    data: reservationData,
    isLoading: isLoadingReservation,
    isError: isErrorReservation,
    refetch: refetchReservation,
  } = useQuery({
    queryKey: [bookingID],
    queryFn: () => fetchReservationInfo(bookingID),
    retry: false,
    refetchOnWindowFocus: false,
    // refetchOnMount: false,
  })

  useEffect(() => {
    if (reservationData && reservationData.status === "cancelled") {
      nav(
        `/modify-reservation/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/${bookingID}/cancelled`,
      )
    }
  }, [reservationData])

  if (isErrorRestaurant)
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center bg-white">
        <h1 className="text-black text-[40px] font-extrabold leading-[60px]">
          Resturant Not Found
        </h1>
      </div>
    )

  if (isErrorReservation)
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center bg-white">
        <h1 className="text-black text-[40px] font-extrabold leading-[60px]">
          Reservation Not Found
        </h1>
      </div>
    )

  if (reservationData && moment(reservationData.dateTime).isBefore(moment())) {
    return (
      <div className="w-[100vw] h-[100vh] flex justify-center items-center bg-white">
        <h1 className="text-black text-[40px] font-extrabold leading-[60px]">
          You can&apos;t modify a past reservation
        </h1>
      </div>
    )
  }

  return (
    <CustomFontComponent fontFamily={tenantConfig?.font ?? ""}>
      <div className="booking-main-div">
        <div className="booking-header-section">
          <div className="booking-header-inner">
            {!showBackButton && (
              <div className="back-icon-style">
                <GoBack />
              </div>
            )}
            <img src={tenantConfig?.logo} alt="Zumi" className="logo-width" />
          </div>
          {isLoadingRestaurant || isLoadingReservation ? (
            <SkeletonView className="skeleton-main" />
          ) : (
            restaurantData &&
            reservationData &&
            brandData && (
              <Outlet
                context={{
                  restaurantData,
                  reservationData,
                  refetchReservation,
                  brandData,
                  localJsonData: tenantConfig,
                }}
              />
            )
          )}
        </div>
      </div>
    </CustomFontComponent>
  )
}

export default ModifyReservation
