import React from "react"

const CompletePaymentLoadingNew = () => {
  return (
    <div className="flex flex-row justify-center items-center gap-2 w-full my-5 bg-transparent text-color-dark p-3 rounded-3xl font-semibold border border-color-dark">
      <div className="loader-blue" />
      <p>Completing payment</p>
    </div>
  )
}

export default CompletePaymentLoadingNew
