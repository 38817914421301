import React, { useState } from "react"
import ContactUs from "../contact/ContactUs"
import HorizontalLine from "../custom/HorizontalLine"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import "./faq.css"

const FAQSUPPORTS = ({ headerSpace }) => {
  const [expandedFAQ, setExpandedFAQ] = useState(["faq"])
  const handleAccordionsetFAQ = (expandedItems) => {
    setExpandedFAQ(expandedItems)
  }
  return (
    <div className="faq-outer-view">
      <div className="faq-inner-view">
        <div className="faq-main-view">
          <div className={`title32px ${headerSpace ? "my-5" : "mb-5"}`}>
            FAQs
          </div>
          <HorizontalLine newDesign />
          <Accordion
            allowZeroExpanded
            preExpanded={expandedFAQ}
            onChange={handleAccordionsetFAQ}
            className="custom-accordion"
          >
            <AccordionItem uuid={"faq"}>
              <AccordionItemButton>
                <p className="faq-title-style">What is Zumi?</p>
              </AccordionItemButton>
              <AccordionItemPanel>
                <div className="text-color-dark">
                  Zumi is a seamless checkout that partners with your favourite
                  restaurants so you can forget about the payment and focus on
                  the meal without having to wait at the end of the meal. When
                  you make a reservation and enter your card details, Zumi holds
                  the details on file (until your reservation is over) to give
                  you the ability to just walk out when you`&apos;re ready to
                  leave the restaurant which should save precious time for both
                  the restaurant and the customer.
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <HorizontalLine newDesign />
          <Accordion allowZeroExpanded className="custom-accordion">
            <AccordionItem>
              <AccordionItemButton>
                <p className="faq-title-style">When will I be charged?</p>
              </AccordionItemButton>
              <AccordionItemPanel>
                <div className="text-color-dark">
                  If you decide to just walk out after your meal then your card
                  on file will be charged within 1 hour of leaving the
                  restaurant. Alternatively, you may decide to pay on your
                  device or split the bill within the 1 hour time frame.
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <HorizontalLine newDesign />
          <Accordion allowZeroExpanded className="custom-accordion">
            <AccordionItem>
              <AccordionItemButton>
                <p className="faq-title-style">
                  How do I change my payment method?
                </p>
              </AccordionItemButton>
              <AccordionItemPanel>
                <div className="text-color-dark">
                  You can click on the `&apos;modify payment method`&apos; link
                  in your email confirmation at any point before the end of your
                  meal to change the payment method. Alternatively, you can also
                  wait to view the bill and change the payment method there.
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>

          <HorizontalLine newDesign />
          <Accordion allowZeroExpanded className="custom-accordion">
            <AccordionItem>
              <AccordionItemButton>
                <p className="faq-title-style">What if I have an issue?</p>
              </AccordionItemButton>
              <AccordionItemPanel>
                <div className="text-color-dark">
                  If you spot an issue with the bill whilst at the restaurant,
                  please speak to the Ida team. If you have left the restaurant
                  and there was an issue with the bill or payment, please get in
                  touch with us below and we will resolve the issue for you.
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <HorizontalLine newDesign />
          <Accordion allowZeroExpanded className="custom-accordion">
            <AccordionItem>
              <AccordionItemButton>
                <p className="faq-title-style">How do we split the bill?</p>
              </AccordionItemButton>
              <AccordionItemPanel>
                <div className="text-color-dark">
                  Zumi enables you to split the bill with your guests. As the
                  reservation holder or a registered guest (a guest that fills
                  out card details ahead of the meal), you will receive an SMS
                  with a link to the bill. When you`&apos;re finished with the
                  meal, you can click on the link to review your bill and then
                  choose whether to pay in full or to split the bill. Once you
                  press split, you will simply state the amount of people at
                  your table and how many people you will be paying for. You
                  will then be able to copy the link (to the bill) and share
                  with your other guests for them to pay.
                  <br />
                  <br />
                  For a guest that hasn`&apos;t registered ahead of the meal,
                  you would just share the link with them and they can pay their
                  share.
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <ContactUs />
      </div>
    </div>
  )
}

export default FAQSUPPORTS
