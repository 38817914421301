import React, { useEffect, useState } from "react"
import {
  useParams,
  useNavigate,
  useLocation,
  useOutletContext,
} from "react-router-dom"
import { useMutation, useQuery } from "@tanstack/react-query"
import moment from "moment"
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"

import {
  CreditCardIcon,
  GroupAddIcon,
  SpinnerIcon,
} from "../../components/custom/Icons"
import ConfirmModal from "./ModifyConfirmModal"
import {
  cancelReservationV2,
  getOccasions,
  savePreferencesV2,
  updateReservationV2,
} from "../../api/v2/reservations"
import CustomFontComponent from "../../components/custom/CustomFontComponent"
import "./modifyReservationStyle.css"
import { toast } from "react-toastify"
import tenantConfig from "../../tenantConfig.json"

const ModifyInformation = () => {
  const nav = useNavigate()
  const { tenantId, subtenantId } = useParams()
  const { state } = useLocation()
  const { restaurantData, reservationData, refetchReservation } =
    useOutletContext()

  const {
    dateTime,
    guestsCount,
    paymentLast4digits,
    guestEmail,
    NewBookingID,
    guestsEmails,
    preferences: reservationPreferences,
    reservationCode,
    restaurantPhone,
  } = reservationData

  const [preferences, setPreferences] = useState({
    occasion: null,
    restrictions: null,
    requests: null,
  })

  const [addNewGuest, setaddNewGuest] = useState(false)
  const [newGuestEmail, setnewGuestEmail] = useState([])
  const [cancelModalVisible, setCancelModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)

  const { data: occasions } = useQuery({
    queryKey: ["occasions"],
    queryFn: () => getOccasions(subtenantId ?? tenantId),
    refetchOnWindowFocus: false,
    enabled: !!tenantId,
  })

  const handleAddMoreGuests = () => {
    setaddNewGuest(true)
    Array.from({ length: guestsCount - 1 }).map((_, id) =>
      setnewGuestEmail((prevNewGuests) => [
        ...prevNewGuests,
        { id, email: "" },
      ]),
    )
  }
  const handleEmailChange = (id, value) => {
    setnewGuestEmail(
      newGuestEmail.map((guestMail) =>
        guestMail.id === id ? { ...guestMail, email: value } : guestMail,
      ),
    )
  }

  const cancelReservationMutation = useMutation({
    mutationFn: cancelReservationV2,
    onSuccess: () => {
      nav(
        `/modify-reservation/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/${NewBookingID}/cancelled`,
      )
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong!")
    },
  })

  const rebookReservationMutation = useMutation({
    mutationFn: updateReservationV2,
    onSuccess: async (data) => {
      refetchReservation()
      nav(
        `/modify-reservation/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/${data.newReservationBookingID}`,
        {
          replace: true,
        },
      )
    },
  })

  const modifyReservationMutation = useMutation({
    mutationFn: async (args) => await savePreferencesV2(...args),
    onSuccess: async () => {
      await Rebook()
    },
    onError: (error) => {
      toast.error(error.message || "Something went wrong!")
    },
  })

  const onEditsSave = async (event) => {
    event.preventDefault()
    modifyReservationMutation.mutate([
      NewBookingID,
      preferences.occasion,
      preferences.restrictions,
      preferences.requests,
    ])
  }

  const Rebook = async () => {
    if (
      moment(dateTime).diff(moment(), "hours") <= 24 ||
      !state.selectedDateTime ||
      !reservationData.id
    )
      return
    rebookReservationMutation.mutate({
      reservationID: reservationData.id,
      dateTime: state.selectedDateTime,
    })
  }
  const gotToCancel = async () => {
    cancelReservationMutation.mutate(NewBookingID)
  }

  const goToModify = (e) => {
    e.preventDefault()
    nav(
      `/modify-reservation/${tenantId}${subtenantId ? `/${subtenantId}` : ""}/${NewBookingID}/calender`,
      {
        state: {
          guestsCount: guestsCount,
          dateTime: dateTime,
        },
      },
    )
  }

  useEffect(() => {
    if (reservationPreferences) {
      setPreferences({ ...reservationPreferences })
    }
  }, [reservationPreferences])

  return (
    <CustomFontComponent
      fontFamily={tenantConfig?.font ?? ""}
      className="w-full"
    >
      <div className="flex flex-col mx-4 gap-y-4 items-center">
        <div className="w-full text-black text-xl font-semibold">
          Your reservation
        </div>
        <div className="border-b border-dashed w-full border-grey-color-shade" />
        <div className="w-full flex flex-col gap-y-[15px] ">
          <div className="w-full flex flex-col gap-y-[8px]">
            <div className=" text-black text-lg font-semibold leading-tight capitalize">
              {restaurantData.name}
            </div>
            <div className=" text-black text-lg font-semibold leading-tight">
              {guestsCount} guests
            </div>
            <div className=" text-black text-lg font-semibold leading-tight">
              {state
                ? moment(state.selectedDateTime).format("ddd, MMM D")
                : moment(dateTime).format("ddd, MMM D")}
            </div>
            <div className=" text-black text-lg font-semibold leading-tight">
              {state
                ? moment(state.selectedDateTime).format("HH:mm")
                : moment(dateTime).format("HH:mm")}
            </div>
            {state && (
              <div className=" text-[#aaafc1] line-through text-sm font-semibold leading-tight">
                {moment(dateTime).format("ddd, MMM D, YYYY HH:mm")}
              </div>
            )}
            <div className="text-black text-lg font-semibold leading-tight">
              Res# {reservationCode}
            </div>

            <div className="text-black text-xs font-semibold leading-tight flex items-center gap-x-[9px]">
              <CreditCardIcon /> Card ending in payment {paymentLast4digits}
            </div>
          </div>
          <div className="flex flex-col gap-y-3 my-2">
            {moment(dateTime).diff(moment(), "hours") <= 24 ? (
              <div className="bg-amber-100 border-amber-400 p-3 rounded-lg border">
                <p className="font-semibold">
                  This reservation cannot be cancelled or modified.
                </p>
                <p>Please call the restaurant at {restaurantPhone} directly</p>
              </div>
            ) : (
              <>
                <div className="w-[210px] h-[34px]  py-3 rounded-3xl border border-black justify-center items-center gap-2 inline-flex">
                  <button
                    onClick={() => setCancelModalVisible(true)}
                    className="text-center text-slate-900 text-base font-medium leading-[17.60px]"
                  >
                    Cancel Reservation
                  </button>
                </div>
                <div className="w-[210px] h-[34px] py-3 rounded-3xl border border-black justify-center items-center gap-2 inline-flex">
                  <button
                    className="text-center text-slate-900 text-base font-medium leading-[17.60px]"
                    onClick={() => setEditModalVisible(true)}
                  >
                    Modify Reservation
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="w-full">
          <div className="confirm-bottom-sec" />
          <Accordion allowZeroExpanded className="custom-accordion">
            <AccordionItem>
              <AccordionItemButton>
                <p className="cnf-bottom-title">What’s the occasion?</p>
              </AccordionItemButton>
              <AccordionItemPanel>
                <div className="cnf-bottom-div">
                  {occasions?.map((occasion, index) => (
                    <div
                      onClick={() => {
                        if (preferences.occasion === occasion) {
                          setPreferences({ ...preferences, occasion: null })
                        } else setPreferences({ ...preferences, occasion })
                      }}
                      key={index}
                      className={`bottom-option ${preferences.occasion === occasion ? "bg-color-dark text-white" : "bg-color-body-default text-color-dark"}`}
                    >
                      <div className="bottom-option-text">{occasion}</div>
                    </div>
                  ))}
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <Accordion
            allowZeroExpanded
            className="custom-accordion showBillInfo"
          >
            <AccordionItem>
              <AccordionItemButton>
                <p className="cnf-bottom-title">
                  Any allergies or restrictions?
                </p>
              </AccordionItemButton>
              <AccordionItemPanel>
                <textarea
                  onChange={(e) =>
                    setPreferences({
                      ...preferences,
                      restrictions: e.target.value,
                    })
                  }
                  defaultValue={preferences.restrictions}
                  className="bottom-textarea"
                />
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <Accordion
            allowZeroExpanded
            className="custom-accordion showBillInfo"
          >
            <AccordionItem>
              <AccordionItemButton>
                <p className="cnf-bottom-title">Any special requests?</p>
              </AccordionItemButton>
              <AccordionItemPanel>
                <textarea
                  onChange={(e) =>
                    setPreferences({
                      ...preferences,
                      requests: e.target.value,
                    })
                  }
                  defaultValue={preferences.requests}
                  className="bottom-textarea"
                />
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
        <div className="border-b border-dashed w-full border-grey-color-shade" />
        <div className="my-[21px] w-full">
          <div className=" h-[35px] text-black text-base font-semibold">
            Who’s coming?
          </div>
          <div className="flex flex-col gap-y-2">
            <div className="flex gap-x-3 items-center">
              <div className="flex justify-center items-center col-span-1 bg-white-color-shade rounded-full w-8 h-8 capitalize">
                {guestEmail[0]}
              </div>
              <div className="flex flex-col">
                <div className="text-black text-base font-medium">
                  {guestEmail.split("@")[0]}
                </div>
                <div className="text-black text-xs font-normal leading-none">
                  Organizer
                </div>
              </div>
            </div>
            {guestsEmails?.map((guest, index) => (
              <div key={index} className="flex gap-x-[16px]">
                <div className="flex justify-center items-center col-span-1 bg-[#D9D9D9] rounded-full w-[30px] h-[30px]">
                  {guest.email[0]}
                </div>
                <div className="col-span-1">
                  <div className=" h-5 text-black text-base font-medium leading-[17.60px]">
                    {guest.email.split("@")[0]}
                  </div>
                  <div className=" h-5 text-black text-xs font-normal leading-none">
                    {guest.status}
                  </div>
                </div>
              </div>
            ))}
            {!addNewGuest && state?.selectedDateTime && (
              <div
                className="flex gap-x-[16px] items-center cursor-pointer"
                onClick={handleAddMoreGuests}
              >
                <GroupAddIcon />
                <div className="text-center text-slate-900 text-base font-medium leading-[17.60px]">
                  Edit Guests
                </div>
              </div>
            )}
            {addNewGuest &&
              newGuestEmail.map((guest, i) => (
                <input
                  key={i}
                  value={guest.value}
                  onChange={(e) => handleEmailChange(guest.id, e.target.value)}
                  type="text"
                  placeholder="Guest email"
                  className="outline-none py-1 border-b border-slate-400"
                />
              ))}
          </div>
        </div>
        <button
          onClick={onEditsSave}
          disabled={
            !(
              preferences.occasion ||
              preferences.restrictions ||
              preferences.requests ||
              newGuestEmail ||
              state
            ) ||
            modifyReservationMutation.isPending ||
            rebookReservationMutation.isPending
          }
          className="bottom-btn"
        >
          <div className="bottom-btn-inner">
            {(modifyReservationMutation.isPending ||
              rebookReservationMutation.isPending) && <SpinnerIcon />}{" "}
            <span>Save</span>
          </div>
        </button>
      </div>
      <ConfirmModal
        visible={cancelModalVisible}
        onClose={() => setCancelModalVisible(false)}
        onClick={gotToCancel}
        isLoading={cancelReservationMutation.isPending}
        header={"Cancel Reservation"}
        discreption={"Are you sure you want to cancel?"}
        confirmText={"Cancel Reservation"}
      />
      <ConfirmModal
        visible={editModalVisible}
        onClose={() => setEditModalVisible(false)}
        onClick={goToModify}
        isLoading={modifyReservationMutation.isPending}
        header={"Modify Reservation"}
        discreption={"Are you sure you want to rebook?"}
        confirmText={"Modify Reservation"}
      />
    </CustomFontComponent>
  )
}

export default ModifyInformation
