import React from "react"

const HorizontalLine = ({ forcedTheme, className, newDesign }) => {
  if (newDesign) {
    return <div className={`${className} "border-t border-grey-color-shade`} />
  }
  return (
    <div
      className={`${className} h-[0px] border-t-[1px] dark:border-[#525C81] border-zinc-300 px-[5px] w-[calc(100vw-6px)] lg:w-[calc(50vw-50px)] ${forcedTheme === "dark" ? "!border-[#525C81]" : "!border-zinc-300"}`}
    />
  )
}

export default HorizontalLine
