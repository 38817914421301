import React, { useEffect, useState } from "react"
import { useNavigate, useOutletContext, useParams } from "react-router"
import { loadStripe } from "@stripe/stripe-js/pure"
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { useMutation } from "@tanstack/react-query"
import { getClientSecret, setDefaultPayment } from "../../api/v2/reservations"
import { CustomLoader, SpinnerIcon } from "../../components/custom/Icons"
import { Helmet } from "react-helmet"
import { toast } from "react-toastify"
import { flushSync } from "react-dom"
import { calcAmountInPence } from "../../utils/helperFunctions"
import {
  addChargeToSvr,
  addPaymentFailure,
  increaseGuestsCount,
  payBillInFull,
  payInFull3DS,
  payInSplit3DS,
  splitBill,
  updateSvrReservation,
} from "../../api/v2/bills"
import "./billStyle.css"

const PaymentFormMainInfo = ({
  state,
  setSomeoneElse,
  setAnotherPayment,
  setIsWalletConfirming,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const nav = useNavigate()
  const { reservationID } = useParams()
  const {
    guestID,
    invoiceData,
    setStatusPaid,
    invoiceID,
    setShowPaymentBtn,
    setPortionPaid,
    brandData,
  } = useOutletContext()
  const [isDisabled, setIsDisabled] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [paymentIntent, setPaymentIntent] = useState()
  const [isLoadingModal, setIsLoadingModal] = useState(true)
  const [isOwner, setIsOwner] = useState(invoiceData?.guestID === guestID)
  const [amountInPound, setAmountInPound] = useState(
    calcAmountInPence(invoiceData, state.portion, state.guestsCount) / 100,
  )
  const [ogData, setOgData] = useState({
    title: "",
    type: "",
    image: "",
    url: "",
    description: "",
  })
  useEffect(() => {
    if (brandData) {
      setOgData({
        title: brandData?.data?.og_tags?.title,
        type: brandData?.data?.og_tags?.type,
        image: brandData?.data?.og_tags?.image,
        url: brandData?.data?.og_tags?.url,
        description: brandData?.data?.og_tags?.description,
      })
    }
  }, [brandData])

  const payInFullMutation = useMutation({
    onMutate: () => setIsWalletConfirming(true),
    mutationFn: payBillInFull,
    onSuccess: async (data) => {
      if (!!data?.next_action?.redirect_to_url) {
        setPaymentIntent(data)
        setIsLoadingModal(true)
        setShowModal(true)
        await flushSync(() => setIsWalletConfirming(false))
        window.scrollTo(0, 0)
        document.body.style.overflow = "hidden"
        return
      }
      updateReservationMutation.mutate({
        reservation_id: reservationID,
        data: { status: "CUSTOM_STATUS_24" },
      })
      addChargeToSvrMutation.mutate({
        reservation_id: reservationID,
        paymentIntentID: data.paymentIntentID,
        amountInPound: invoiceData.price,
        subtotal: invoiceData.subtotal,
      })
      setIsWalletConfirming(false)
      setStatusPaid(true)
      setShowPaymentBtn(false)
    },
    onError: (err) => {
      setIsWalletConfirming(false)
      toast.error(err.message ? err.message : "pay in full failed")
      setIsDisabled(false)
    },
  })

  const payInFull3DSMutation = useMutation({
    mutationFn: payInFull3DS,
    onSuccess: (data) => {
      updateReservationMutation.mutate({
        reservation_id: reservationID,
        data: { status: "CUSTOM_STATUS_24" },
      })
      addChargeToSvrMutation.mutate({
        reservation_id: reservationID,
        paymentIntentID: data.paymentIntentID,
        amountInPound: invoiceData.price,
        subtotal: invoiceData.subtotal,
      })
      setShowModal(false)
      document.body.style.overflow = "auto"
      setStatusPaid(true)
      setShowPaymentBtn(false)
      setIsLoadingModal(false)
    },
    onError: (err) => {
      setShowModal(false)
      document.body.style.overflow = "auto"
      toast.error(err.message ?? "payment in 3ds failed")
      setIsLoadingModal(false)
      setIsDisabled(false)
    },
  })

  const splitBillMutation = useMutation({
    onMutate: () => setIsWalletConfirming(true),
    mutationFn: splitBill,
    onSuccess: async (data) => {
      if (!!data?.next_action?.redirect_to_url) {
        setPaymentIntent(data)
        setIsLoadingModal(true)
        setShowModal(true)
        await flushSync(() => setIsWalletConfirming(false))
        window.scrollTo(0, 0)
        document.body.style.overflow = "hidden"
        return
      }
      if (data.status === "paid") {
        updateReservationMutation.mutate({
          reservation_id: reservationID,
          data: { status: "CUSTOM_STATUS_24" },
        })
        addChargeToSvrMutation.mutate({
          reservation_id: reservationID,
          paymentIntentID: data.paymentIntentID,
          amountInPound: invoiceData.price,
          subtotal: invoiceData.subtotal,
        })
      } else {
        updateReservationMutation.mutate({
          reservation_id: reservationID,
          data: { status: "CUSTOM_STATUS_25" },
        })
      }
      setIsWalletConfirming(false)
      setShowPaymentBtn(false)
      setPortionPaid(data.portionPaid)
      setStatusPaid(data.status === "paid")
      if (setSomeoneElse) {
        setSomeoneElse(true)
      }
      if (setAnotherPayment) {
        setAnotherPayment(false)
      }
    },
    onError: (err) => {
      setIsWalletConfirming(false)
      toast.error(err.message ? err.message : "Please try again")
      setIsDisabled(false)
    },
  })
  const splitBill3DSMutation = useMutation({
    mutationFn: payInSplit3DS,
    onSuccess: (data) => {
      if (data.status === "paid") {
        updateReservationMutation.mutate({
          reservation_id: reservationID,
          data: { status: "CUSTOM_STATUS_24" },
        })
        addChargeToSvrMutation.mutate({
          reservation_id: reservationID,
          paymentIntentID: data.paymentIntentID,
          amountInPound: invoiceData.price,
          subtotal: invoiceData.subtotal,
        })
      } else {
        updateReservationMutation.mutate({
          reservation_id: reservationID,
          data: { status: "CUSTOM_STATUS_25" },
        })
      }
      setShowModal(false)
      document.body.style.overflow = "auto"
      setShowPaymentBtn(false)
      setStatusPaid(data.status === "paid")
      setPortionPaid(data.portionPaid)
      setIsLoadingModal(false)
      if (setSomeoneElse) {
        setSomeoneElse(true)
      }
      if (setAnotherPayment) {
        setAnotherPayment(false)
      }
    },
    onError: (err) => {
      setIsDisabled(false)
      document.body.style.overflow = "auto"
      setShowModal(false)
      toast.error(err.message ? err.message : "Please try again")
      setIsLoadingModal(false)
    },
  })

  const updateReservationMutation = useMutation({
    mutationFn: updateSvrReservation,
    onSuccess: () => {},
    onError: (err) => {
      toast.error(err.message ?? "updating reservation failed")
    },
  })

  const addChargeToSvrMutation = useMutation({
    mutationFn: addChargeToSvr,
    onSuccess: () => {},
    onError: (err) => {
      toast.error(err.message ?? "adding charge failed")
    },
  })

  const clientSecretMutation = useMutation({
    mutationFn: getClientSecret,
  })
  const mutateDefaultPayment = useMutation({
    mutationFn: setDefaultPayment,
    onError: (err) => {
      toast.error(err.message ? err.message : "Please try again")
      setIsDisabled(false)
    },
  })
  const addPaymentFailedMutation = useMutation(addPaymentFailure)
  const increaseGuestsCountMutation = useMutation({
    mutationFn: increaseGuestsCount,
    onError: (err) => {
      toast.error(err.message ? err.message : "Please try again")
      setIsDisabled(false)
    },
  })

  const onCardInput = async () => {
    try {
      setIsWalletConfirming(true)
      setIsDisabled(true)
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        setIsDisabled(false)
        setIsWalletConfirming(false)
        return null
      }
      const { error: submitError } = await elements.submit()
      if (submitError) {
        toast.error(submitError.message)
        setIsDisabled(false)
        setIsWalletConfirming(false)
        return
      }

      const { client_secret, customerID } =
        await clientSecretMutation.mutateAsync({
          guestName:
            invoiceData?.firstName && invoiceData?.lastName
              ? `${invoiceData?.firstName} ${invoiceData?.lastName}`
              : "no name specified",
          guestEmail: `${invoiceData?.guestEmail ?? "no mail specified"}`,
        })

      const { error, setupIntent } = await stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        clientSecret: client_secret,
        confirmParams: {
          return_url: `${window.location.origin}/bill-info/${reservationID}/?invited&&id=${guestID}`,
        },
        redirect: "if_required",
      })
      if (error) {
        toast.error(error.message)
        addPaymentFailedMutation.mutate({
          reservationID,
          payment_intent: {
            id: error.setup_intent.id,
            amount:
              state.payMethod === "full"
                ? Math.round(invoiceData.price * 100)
                : calcAmountInPence(
                    invoiceData,
                    state.portion,
                    state.guestsCount,
                  ),
            customer: customerID,
          },
          payment_method: error.payment_method,
          isOwner,
          reason: error.message,
        })
        setIsDisabled(false)
        setIsWalletConfirming(false)
        return
      }

      if (!invoiceData.guestsCountUpdated && state.payMethod === "split") {
        await increaseGuestsCountMutation.mutateAsync({
          reservationID,
          guestCount: state.guestsCount,
        })
      }

      if (!!guestID) {
        await mutateDefaultPayment.mutateAsync({
          reservationID,
          paymentID: setupIntent.payment_method,
          customerID,
        })
      }

      if (state.payMethod === "full") {
        payInFullMutation.mutate({
          billID: invoiceID,
          stripeCustomerID: customerID,
          paymentMethodID: !guestID && setupIntent.payment_method,
          isOwner: isOwner,
        })
      } else if (state.payMethod === "split") {
        splitBillMutation.mutate({
          billID: invoiceID,
          portion: state.portion,
          stripeCustomerID: customerID,
          reservationID: invoiceData.reservationID,
          guestCount: invoiceData.guestsCount,
          guestID: guestID,
          paymentMethodID: !guestID && setupIntent.payment_method,
          isOwner: isOwner,
        })
      }
    } catch (error) {
      setIsDisabled(false)
      setIsWalletConfirming(false)
    }
  }

  useEffect(() => {
    const handleMessage = async (event) => {
      if (
        event.data === "3DS-authentication-complete" &&
        paymentIntent?.client_secret
      ) {
        const { paymentIntent: intent } = await stripe.retrievePaymentIntent(
          paymentIntent.client_secret,
        )
        if (state.payMethod === "full") {
          if (intent.status === "succeeded") {
            payInFull3DSMutation.mutate({
              invoiceID,
              paymentIntentID: intent.id,
            })
          } else {
            toast.error(
              intent.last_payment_error.message ?? "authenticating failed",
            )
            setIsDisabled(false)
            setShowModal(false)
            setIsLoadingModal(false)
            document.body.style.overflow = "auto"
          }
        } else if (state.payMethod === "split") {
          if (intent.status === "succeeded") {
            splitBill3DSMutation.mutate({
              invoiceID,
              paymentIntentID: intent.id,
              portion: state.portion,
            })
          } else {
            toast.error(
              intent.last_payment_error.message ?? "authenticating failed",
            )
            setIsDisabled(false)
            setShowModal(false)
            setIsLoadingModal(false)
            document.body.style.overflow = "auto"
          }
        }
      }
    }
    window.addEventListener("message", handleMessage, false)
    return () => {
      window.removeEventListener("message", handleMessage)
    }
  }, [stripe, paymentIntent])

  useEffect(() => {
    if (invoiceData) {
      const owner = invoiceData.guestID === guestID
      setIsOwner(owner)
    }
  }, [invoiceData.guestID])

  useEffect(() => {
    if (state.portion > 0 && state.guestsCount > 0 && !!invoiceData) {
      setAmountInPound(
        calcAmountInPence(invoiceData, state.portion, state.guestsCount) / 100,
      )
    }
  }, [state.portion, invoiceData, state.guestsCount])

  if ((!guestID || !state) && invoiceData.status === "split") {
    nav("/")
    return null
  }

  return (
    <>
      <Helmet>
        <meta property="og:title" content={ogData.title} />
        <meta property="og:type" content={ogData.type} />
        <meta property="og:image" content={ogData.image} />
        <meta property="og:url" content={ogData.url} />
        <meta property="og:description" content={ogData.description} />
      </Helmet>
      <div className="payment-outer-view">
        <div className="payment-inner-view">
          <div className="width-max">
            <PaymentElement
              options={{
                terms: { card: "never" },
                wallets: { applePay: "never", googlePay: "never" },
                layout: {
                  type: "accordion",
                  defaultCollapsed: false,
                  radios: false,
                  spacedAccordionItems: true,
                },
              }}
            />
          </div>
        </div>
        <button
          disabled={isDisabled}
          onClick={onCardInput}
          className="card-input-view"
        >
          <div className="card-input-inner-view">
            <div className="card-input-outer-view">
              {isDisabled &&
                (clientSecretMutation.isPending ||
                  splitBillMutation.isPending ||
                  payInFullMutation.isPending) && <SpinnerIcon />}{" "}
              {state.payMethod === "full" ? (
                <span>Pay £{invoiceData.price.toFixed(2)}</span>
              ) : (
                <span>Pay £{amountInPound.toFixed(2)}</span>
              )}
            </div>
          </div>
        </button>
      </div>
      {showModal && isLoadingModal && paymentIntent && (
        <div className="processing-fixed">
          <CustomLoader />
          <p className="loader-fetching-text">Processing...</p>
        </div>
      )}
      {showModal && paymentIntent && (
        <div className="payment-intent-view">
          <iframe
            className="h-w-full"
            data-hj-allow-iframe="true"
            title="stripeconfirm"
            src={paymentIntent.next_action.redirect_to_url.url}
          ></iframe>
        </div>
      )}
    </>
  )
}

loadStripe.setLoadParameters({ advancedFraudSignals: false })
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHED_KEY)

const PaymentFormInfo = ({
  state,
  setSomeoneElse,
  setAnotherPayment,
  setIsWalletConfirming,
}) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "setup",
        currency: "gbp",
        appearance: {
          // theme: "night",
          variables: {
            // colorBackground: "#2A2B49",
            // colorPrimary: "#FFF",
          },
          rules: {
            ".AccordionItem": {
              border: 0,
              boxShadow: "none",
            },
          },
        },
      }}
    >
      <PaymentFormMainInfo
        state={state}
        setSomeoneElse={setSomeoneElse}
        setAnotherPayment={setAnotherPayment}
        setIsWalletConfirming={setIsWalletConfirming}
      />
    </Elements>
  )
}

export default PaymentFormInfo
