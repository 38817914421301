import React, { useEffect } from "react"
import { ArrowIconDark, StarIconLightBlue } from "../../components/custom/Icons"
import "../../styles/style.css"

const SideMenu = (props) => {
  const { toggleMenu, toggleMenuHandler, setMenuIndex } = props
  const handleClick = (index) => {
    toggleMenuHandler()
    setMenuIndex(index)
  }
  const reservationID = sessionStorage.getItem("ReservationId")

  useEffect(() => {
    if (toggleMenu) {
      document.body.classList.add("!overflow-hidden")
    } else {
      document.body.classList.remove("!overflow-hidden")
    }
    return () => {
      document.body.classList.remove("!overflow-hidden")
    }
  }, [toggleMenu])

  return (
    <div className="side-menu-div" onClick={() => toggleMenuHandler()}>
      <div className="side-menu-outer-view sidebarMenu">
        <div className="side-menu-inner-view">
          {reservationID && (
            <div onClick={() => handleClick(1)} className="side-menu-text-div">
              <div className="side-menu-text">Your bill</div>
              <ArrowIconDark />
            </div>
          )}
          <div onClick={() => handleClick(2)} className="side-menu-text-div">
            <div className="side-menu-text">FAQs</div>
            <ArrowIconDark />
          </div>
          <div onClick={() => handleClick(3)} className="side-menu-text-div">
            <div className="side-menu-text">Terms & conditions</div>
            <ArrowIconDark />
          </div>
        </div>
        <div className="side-menu-bottom-view">
          <StarIconLightBlue />
          <p className="text-grey-dark-shade">Powered by Zumi</p>
        </div>
      </div>
    </div>
  )
}

export default SideMenu
