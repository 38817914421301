import React from "react"
import { useNavigate } from "react-router-dom"

const GoBack = ({ color = "#17243A" }) => {
  const nav = useNavigate()
  return (
    <div onClick={() => nav(-1)} className="cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
      >
        <g clipPath="url(#clip0_244_2195)">
          <path
            d="M16.0522 7.71875L14.5835 6.25L8.3335 12.5L14.5835 18.75L16.0522 17.2812L11.2814 12.5L16.0522 7.71875Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_244_2195">
            <rect width="25" height="25" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}

export default GoBack
